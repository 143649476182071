import React, { useState, useEffect } from 'react'
import MaterialTable, { MTableToolbar } from 'material-table'
import AddIcon from '@material-ui/icons/Add'
import Button from '@material-ui/core/Button'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import CheckIcon from '@material-ui/icons/Check'
import SearchIcon from '@material-ui/icons/Search'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import LastPageIcon from '@material-ui/icons/LastPage'
import PreviousPageIcon from '@material-ui/icons/SkipPrevious'
import NextPageIcon from '@material-ui/icons/SkipNext'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ClearIcon from '@material-ui/icons/Clear'
import IconButton from '@material-ui/core/IconButton'
import ExportIcon from '@material-ui/icons/CloudDownload'
import MomentUtils from '@date-io/moment'
import _, { map } from 'underscore'

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers'

const axios = require("axios")
var store = require("store")

export default function CustomerReport() {
  const [data, setData] = useState([])
  const [selectedStartDate, setSelectedStartDate] = React.useState(null)
  const [selectedEndDate, setSelectedEndDate] = React.useState(null)

  useEffect(() => {
    getUsers()
  }, [])

  const getUsers = () => {
    if (selectedStartDate == null || selectedEndDate == null) {
      setData([])
    } else {
      axios.get('https://suite-44-api.herokuapp.com/reservationList/report/' + selectedStartDate + '/' + selectedEndDate, {
        headers: {
          'x-access-token': store.get('token')
        }
      })
        .then((response) => {
          console.log("Staff Report", response)
          var newlist = _.map(_.groupBy(response.data, "clientId"), function (v, n) {
            let allRevenue = _.pluck(v, "amountSpent").filter(Number)
            let totalRevenue = allRevenue.reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
            return { clientId: n, clientName: _.pluck(v, "clientName")[0], revenue: totalRevenue }
          })
          setData(newlist)
        })
    }
  }

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
  }

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
  }

  const handleStartClear = () => {
    setSelectedStartDate(null);
  }

  const handleEndClear = () => {
    setSelectedEndDate(null);
  }

  const setDateFilter = () => {
    getUsers()
  }

  return (
    <div>
      <div style={{ paddingTop: '12px' }}>
        <MaterialTable
          components={{
            Toolbar: props => (
              <div>
                <MTableToolbar {...props} />
                <div style={{ padding: '25px' }}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      style={{ marginRight: '25px' }}
                      InputProps={{
                        readOnly: true,
                        startAdornment: (
                          <IconButton onClick={handleStartClear} disabled={!selectedStartDate} style={{ order: 1 }}>
                            <ClearIcon color="disabled" fontSize="small" />
                          </IconButton>
                        )
                      }}
                      InputAdornmentProps={{
                        position: "end",
                        style: { order: 2, marginLeft: 0 }
                      }}
                      error={false}
                      helperText={null}
                      clearable="true"
                      variant="inline"
                      format="MM/DD/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label="Start Date"
                      value={selectedStartDate}
                      onChange={handleStartDateChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                    <KeyboardDatePicker
                      style={{ marginRight: '25px' }}
                      InputProps={{
                        readOnly: true,
                        startAdornment: (
                          <IconButton onClick={handleEndClear} disabled={!selectedEndDate} style={{ order: 1 }}>
                            <ClearIcon color="disabled" fontSize="small" />
                          </IconButton>
                        )
                      }}
                      InputAdornmentProps={{
                        position: "end",
                        style: { order: 2, marginLeft: 0 }
                      }}
                      error={false}
                      helperText={null}
                      variant="inline"
                      format="MM/DD/yyyy"
                      clearable="true"
                      margin="normal"
                      id="date-picker-inline"
                      label="End Date"
                      value={selectedEndDate}
                      onChange={handleEndDateChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                    <Button color="primary" style={{ marginTop: '40px' }} variant="contained" autoFocus onClick={setDateFilter} >
                      Filter by Date
                    </Button>
                  </MuiPickersUtilsProvider>
                </div>
              </div>
            ),
          }}
          columns={[
            { title: 'Client Name', field: 'clientName', type: 'string' },
            { title: 'Total Amount Spent ($)', field: 'revenue', type: 'string' }
          ]}
          data={data}
          title={<h1>Staff Report - Rating and Revenue</h1>}
          editable={false}
          options={{
            pageSize: 10,
            pageSizeOptions: [10, 50, 100],
            exportButton: { csv: true, pdf: false }
          }}
          icons={{
            Add: props => <AddIcon {...props} />,
            Edit: props => <EditIcon {...props} />,
            Delete: props => <DeleteIcon {...props} />,
            Clear: props => <DeleteIcon {...props} />,
            Check: props => <CheckIcon {...props} />,
            Search: props => <SearchIcon {...props} />,
            ResetSearch: props => <ClearIcon {...props} />,
            FirstPage: props => <FirstPageIcon {...props} />,
            LastPage: props => <LastPageIcon {...props} />,
            PreviousPage: props => <PreviousPageIcon {...props} />,
            NextPage: props => <NextPageIcon {...props} />,
            Clear: props => <ClearIcon {...props} />,
            Export: props => <ExportIcon {...props} />,
            SortArrow: props => <ArrowDownwardIcon {...props} />
          }}
        />
      </div>
    </div>
  )
}