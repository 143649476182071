import React, { useState, useEffect } from 'react'
import MaterialTable, { MTableToolbar } from 'material-table'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import CheckIcon from '@material-ui/icons/Check'
import SearchIcon from '@material-ui/icons/Search'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import LastPageIcon from '@material-ui/icons/LastPage'
import PreviousPageIcon from '@material-ui/icons/SkipPrevious'
import NextPageIcon from '@material-ui/icons/SkipNext'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ClearIcon from '@material-ui/icons/Clear'
import NotificationIcon from '@material-ui/icons/PhoneAndroid'
import RefreshIcon from '@material-ui/icons/Refresh'
import Button from "@material-ui/core/Button"
import { enabled } from 'store'
import CustomSnackbar from "../shared/CustomSnackbar"
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { resolve } from 'path'

const axios = require("axios")
const querystring = require('querystring')
var store = require("store")

export default function ReservationList(props) {
  const [data, setData] = useState([])
  const [clients, setClients] = useState([])
  const [clientsObj, setClientsObj] = useState([])
  const [staff, setStaff] = useState([])
  const [staffObj, setStaffObj] = useState([])
  const [tables, setTables] = useState([])
  const [tablesObj, setTablesObj] = useState([])
  const [snackbarIsOpen, setSnackbarIsOpen,] = useState(false)

  useEffect(() => {
    getClients()
    getStaff()
    getTables()
    getReservationList()
  }, [])

  const getClients = () => {
    axios.get('https://suite-44-api.herokuapp.com/users', {
      headers: {
        'x-access-token': store.get('token')
      }
    })
      .then((response) => {
        var result = response.data.map(function (client, index) {
          return { "name": client.firstName + " " + client.lastName, "index": index, "id": client._id }
        })
        setClients(result)
        setClientsObj(response.data)
      })
  }

  const getStaff = () => {
    axios.get('https://suite-44-api.herokuapp.com/staff', {
      headers: {
        'x-access-token': store.get('token')
      }
    })
      .then((response) => {
        var result = response.data.map(function (staff) {
          return staff.firstName + " " + staff.lastName
        })
        setStaff(result)
        setStaffObj(response.data)
      })
  }

  const getTables = () => {
    axios.get('https://suite-44-api.herokuapp.com/tables', {
      headers: {
        'x-access-token': store.get('token')
      }
    })
      .then((response) => {
        var result = response.data.map(function (table) {
          return table.name
        })
        setTables(result)
        setTablesObj(response.data)
      })
  }

  const getReservationList = () => {
    axios.get('https://suite-44-api.herokuapp.com/reservationList/reservations/' + props.id, {
      headers: {
        'x-access-token': store.get('token')
      }
    })
      .then((response) => {
        setData(response.data)
      })
  }

  const onSnackBarClose = () => {
    setSnackbarIsOpen(false)
  }

  const sendMessage = (phone, name, id) => {
    console.log('Phone', phone, id)
    const params = `{"name":"${name}", "id": "${id}" }`

    axios.post('https://studio.twilio.com/v2/Flows/FW5ab48007c430dcea91e92672f1a68c33/Executions', querystring.stringify({
      Parameters: params,
      From: '+12264570751',
      To: '+1' + phone
    }), {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Authorization": `Basic ${Buffer.from('ACcb144304b29733a0419101f1f5eb84ab' + ':' + '0bc0e0a176bbc73092d39e309ef91471').toString('base64')}`
      }
    })
      .then(response => {
        const { body, to, status } = response.data
        console.log("Successful!", {
          body,
          to,
          status
        })
        setSnackbarIsOpen(true)
      })
      .catch(error => {
        console.log("Error", error.response.data)
      })
  }

  const sendSMS = () => {
    for (var i = 0; i < data.length; i++) {
      const selectedClient = clientsObj.find(client => client._id === data[i].clientId)
      sendMessage(selectedClient.phone, selectedClient.firstName, data[i]._id)
    }
  }

  return (
    <div>
      <div style={{ paddingTop: '12px' }}>
        <MaterialTable
          components={{
            Toolbar: props => (
              <div>
                <MTableToolbar {...props} />
                <div style={{ paddingLeft: '60px', paddingTop: '20px', paddingBottom: '20px' }}>
                  <Button color="primary" variant="contained" autoFocus onClick={sendSMS}>
                    Send SMS to all
                  </Button>
                </div>
              </div>
            ),
          }}
          columns={[
            {
              title: 'Client', field: 'clientName', validate: rowData => rowData.firstName !== '', editComponent: props => {
                console.log({ clients, props })
                const selectedClient = clients.find(client => client.name === props.rowData.clientName)
                return (
                  <Autocomplete
                    id="Agent Emails"
                    size="small"
                    defaultValue={selectedClient}
                    options={clients}
                    getOptionLabel={option => option.name}
                    renderInput={params => {
                      return (
                        <TextField
                          {...params}
                          label={""}
                          fullWidth
                        />
                      );
                    }}
                    onChange={(event, newValue) => props.onChange(newValue)}
                    onKeyDown={(event) => {
                      event.stopPropagation()
                      console.log("key pressed")
                    }}
                  />
                )
              }
            },
            { title: 'Served by', field: 'staffName', lookup: staff, validate: rowData => rowData.lastName !== '' },
            { title: 'Table', field: 'tableName', lookup: tables, validate: rowData => rowData.email !== '' },
            { title: 'Rating', field: 'rating', validate: rowData => rowData.phone !== '', editable: false },
            { title: 'Feedback', field: 'feedback', validate: rowData => rowData.staff !== '', editable: false },
            { title: 'Amount Spent ($)', field: 'amountSpent', validate: rowData => rowData.table !== '' }
          ]}
          data={data}
          title={''}
          options={{
            pageSize: 10,
            pageSizeOptions: [10, 50, 100]
          }}
          icons={{
            Add: props => <AddIcon {...props} />,
            Edit: props => <EditIcon {...props} />,
            Delete: props => <DeleteIcon {...props} />,
            Clear: props => <DeleteIcon {...props} />,
            Check: props => <CheckIcon {...props} />,
            Search: props => <SearchIcon {...props} />,
            ResetSearch: props => <ClearIcon {...props} />,
            FirstPage: props => <FirstPageIcon {...props} />,
            LastPage: props => <LastPageIcon {...props} />,
            PreviousPage: props => <PreviousPageIcon {...props} />,
            NextPage: props => <NextPageIcon {...props} />,
            Clear: props => <ClearIcon {...props} />,
            SortArrow: props => <ArrowDownwardIcon {...props} />
          }}
          actions={[
            {
              icon: () => <NotificationIcon />,
              tooltip: 'Send message',
              onClick: (event, rowData) => {
                const selectedClient = clientsObj.find(client => client._id === rowData.clientId)
                sendMessage(selectedClient.phone, selectedClient.firstName, rowData._id)
              }
            },
            {
              icon: () => <RefreshIcon />,
              tooltip: 'Reload',
              onClick: (event, rowData) => {
                getReservationList()
              }
            }
          ]}
          editable={{
            onRowAdd: newData => {
              return new Promise((resolve, reject) => {
                if (!newData.clientName || !newData.staffName || !newData.tableName) {
                  reject()
                }
                axios.post('https://suite-44-api.herokuapp.com/reservationList', {
                  clientName: newData.clientName.name,
                  clientId: newData.clientName.id,
                  staffName: newData.staffName,
                  staffId: staffObj[newData.staffName]._id,
                  staffFullName: staffObj[newData.staffName].firstName + " " + staffObj[newData.staffName].lastName,
                  tableName: newData.tableName,
                  tableId: tablesObj[newData.tableName]._id,
                  reservationId: props.id,
                  amountSpent: newData.amountSpent,
                  date: props.date
                }, {
                  headers: {
                    'x-access-token': store.get('token')
                  }
                })
                  .then((response) => {
                    setData([...data, response.data])
                    resolve()
                  }, (error) => {
                    console.log(error)
                    reject()
                  })
              })
            },
            onRowUpdate: (newData, oldData) => {
              return new Promise((resolve, reject) => {
                if (!newData.clientName || !newData.staffName || !newData.tableName) {
                  reject()
                }
                axios.patch('https://suite-44-api.herokuapp.com/reservationList/' + oldData._id, {
                  clientName: newData.clientName.name,
                  clientId: newData.clientName.id,
                  staffName: newData.staffName,
                  staffId: staffObj[newData.staffName]._id,
                  staffFullName: staffObj[newData.staffName].firstName + " " + staffObj[newData.staffName].lastName,
                  tableName: newData.tableName,
                  tableId: tablesObj[newData.tableName]._id,
                  reservationId: props.id,
                  rating: newData.rating,
                  feedback: newData.feedback,
                  amountSpent: newData.amountSpent,
                  date: props.date
                }, {
                  headers: {
                    'x-access-token': store.get('token')
                  }
                })
                  .then((response) => {
                    const dataUpdate = [...data]
                    const index = oldData.tableData.id
                    dataUpdate[index] = response.data
                    setData([...dataUpdate])
                    resolve()
                  }, (error) => {
                    console.log(error)
                    reject()
                  })
              })
            },
            onRowDelete: oldData =>
              axios.delete('https://suite-44-api.herokuapp.com/reservationList/' + oldData._id, {
                headers: {
                  'x-access-token': store.get('token')
                }
              })
                .then((response) => {
                  const dataDelete = [...data]
                  const index = oldData.tableData.id
                  dataDelete.splice(index, 1)
                  setData([...dataDelete])
                }, (error) => {
                  console.log(error)
                })
          }}
        />
      </div>
      <CustomSnackbar
        variant="success"
        message="Message successfully sent"
        open={snackbarIsOpen}
        onSnackBarClose={onSnackBarClose}
      />
    </div>
  )
}
