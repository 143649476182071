import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { palette, mainTheme } from '../../constants/styles'
import Dialog from '@material-ui/core/Dialog'
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Slide from '@material-ui/core/Slide'
import MaterialTable from 'material-table'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import CheckIcon from '@material-ui/icons/Check'
import SearchIcon from '@material-ui/icons/Search'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import LastPageIcon from '@material-ui/icons/LastPage'
import PreviousPageIcon from '@material-ui/icons/SkipPrevious'
import NextPageIcon from '@material-ui/icons/SkipNext'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ClearIcon from '@material-ui/icons/Clear'
import ListIcon from '@material-ui/icons/List'

import ReservationList from './ReservationList'

const axios = require("axios")
const moment = require("moment")
var store = require("store")

const useStyles = makeStyles((mainTheme) => ({
  appBar: {
    background: palette.black[0],
    position: 'relative'
  },
  title: {
    marginLeft: mainTheme.spacing(2),
    flex: 1
  },
}))

export default function Reservations() {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [data, setData] = useState([])
  const [selectedId, setSelectedId] = useState('')
  const [selectedDate, setSelectedDate] = useState('')

  useEffect(() => {
    getReservations()
  }, [])

  const getReservations = () => {
    axios.get('https://suite-44-api.herokuapp.com/reservations', {
      headers: {
        'x-access-token': store.get('token')
      }
    })
      .then((response) => {
        setData(response.data)
      })
  }

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
  })

  const handleClose = () => {
    setOpen(false)
  }

  const openReservationList = (id, date) => {
    setSelectedId(id)
    setSelectedDate(date)
    setOpen(true)
  }

  return (
    <div>
      <div style={{ paddingTop: '12px' }}>
        <MaterialTable
          columns={[
            { title: 'Reservation Date', field: 'date', type: 'date' }
            //{ title: 'Message Time', field: 'time', type: 'datetime' }
          ]}
          data={data}
          title={<h1>Reservations</h1>}
          options={{
            pageSize: 10,
            pageSizeOptions: [10, 50, 100]
          }}
          icons={{
            Add: props => <AddIcon {...props} />,
            Edit: props => <EditIcon {...props} />,
            Delete: props => <DeleteIcon {...props} />,
            Clear: props => <DeleteIcon {...props} />,
            Check: props => <CheckIcon {...props} />,
            Search: props => <SearchIcon {...props} />,
            ResetSearch: props => <ClearIcon {...props} />,
            FirstPage: props => <FirstPageIcon {...props} />,
            LastPage: props => <LastPageIcon {...props} />,
            PreviousPage: props => <PreviousPageIcon {...props} />,
            NextPage: props => <NextPageIcon {...props} />,
            Clear: props => <ClearIcon {...props} />,
            SortArrow: props => <ArrowDownwardIcon {...props} />
          }}
          editable={{
            onRowAdd: newData =>
              axios.post('https://suite-44-api.herokuapp.com/reservations', {
                date: newData.date,
                time: newData.date
              }, {
                headers: {
                  'x-access-token': store.get('token')
                }
              })
                .then((response) => {
                  setData([...data, response.data])
                }, (error) => {
                  console.log(error)
                }),
            onRowDelete: oldData =>
              axios.delete('https://suite-44-api.herokuapp.com/reservations/' + oldData._id, {
                headers: {
                  'x-access-token': store.get('token')
                }
              })
                .then((response) => {
                  const dataDelete = [...data]
                  const index = oldData.tableData.id
                  dataDelete.splice(index, 1)
                  setData([...dataDelete])
                }, (error) => {
                  console.log(error)
                })
          }}
          actions={[
            {
              icon: () => <ListIcon />,
              tooltip: 'Display reservation list',
              onClick: (event, rowData) => {
                openReservationList(rowData._id, rowData.date)
              }
            }
          ]}
        />
      </div>
      <Dialog fullScreen open={open} onClose={handleClose}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <ClearIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Reservation List - {moment(selectedDate).format("MMM Do YYYY")}
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              Save
            </Button>
          </Toolbar>
        </AppBar>
        <ReservationList id={selectedId} date={selectedDate} />
      </Dialog>
    </div>
  )
}