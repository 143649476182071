import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { palette } from '../../constants/styles'

const styles = ({
  container: {
    background: palette.black[-2],
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    width: '100%'
  },

  footer: {
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '50px 0 calc(100vh / 3) 0',
    width: '100%',

    '@media (min-height: 2000px)': {
      margin: '50px 0 calc(100vh / 2) 0'
    },

    '@media (min-height: 3000px)': {
      margin: '50px 0 calc(100vh / 1.5) 0'
    },
  },

  leftFooter: {
    fontSize: '14px',
    color: palette.white[0],
    margin: '25px 0 0 0',
    padding: '0 0 0 180px',

    '@media (max-width: 1400px)': {
      padding: '0 0 0 100px',
    },

    '@media (max-width: 1300px)': {
      padding: '0 0 0 50px',
    },

    '@media (max-width: 790px)': {
      padding: '0 0 0 40px',
    },

    '@media (max-width: 479px)': {
      padding: '0 0 0 25px',
    }
  },

  rightFooter: {
    alignItems: 'center',
    color: palette.white[0],
    display: 'flex',
    flexWrap: 'wrap',
    fontSize: '14px',
    padding: '10px 170px 0 0',

    '@media (max-width: 1400px)': {
      padding: '0 90px 0 40px',
    },

    '@media (max-width: 1300px)': {
      padding: '0 40px 0 40px',
    },

    '@media (max-width: 790px)': {
      padding: '0 30px 0 30px',
    },

    '@media (max-width: 479px)': {
      padding: '0 15px 0 15px',
    },
  },

  link: {
    fontSize: '14px',
    color: palette.white[0],
    margin: '15px 10px 0 10px',
  },

  linkWithIcon: {
    fontSize: '14px',
    color: palette.white[0],
    margin: 0,
  },

  linkIcon: {
    width: 12,
    heigh: 12,
    margin: '0 5px 0 0'
  },

  linkIconEmail: {
    color: palette.white[0],
    width: 14,
    heigh: 14,
    margin: '0 5px 0 0'
  },

  linkIconContainer: {
    alignItems: 'center',
    color: palette.white[0],
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    margin: '15px 10px 0 10px'
  }

})

class Footer extends Component {
  render() {
    const { classes } = this.props

    return (
      <div className={classes.container}>
        <div className={classes.footer}>
          <div className={classes.leftFooter}></div>
          <div className={classes.rightFooter}>
            Copyright © 2020 44 Toronto Inc. All rights reserved.
          </div>
        </div>
      </div>
    )
  }
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Footer)