import React, { Component } from 'react'
import PropTypes from 'prop-types'

import SignIn from './Signin'
import Main from './Main'

class Navigation extends Component {
  state = {
    isLoggedIn: false
  }

  updateLoginStatus = (status) => {
    this.setState({
      isLoggedIn: status
    })
  }

  getCurrentView = () => {
    const { isLoggedIn } = this.state

    if (isLoggedIn === true) {
      return (<Main updatedLogin={(status) => this.updateLoginStatus(status)} />)
    } else {
      return (<SignIn updatedLogin={(status) => this.updateLoginStatus(status)} />)
    }
  }

  render() {
    return (
      this.getCurrentView()
    )
  }
}

Navigation.propTypes = {
  classes: PropTypes.object.isRequired
}

export default (Navigation)