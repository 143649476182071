import React from "react"
import ReactDOM from "react-dom"
import "../src/index.css"
import { BrowserRouter, Route, Switch } from "react-router-dom"
import { MuiThemeProvider } from "@material-ui/core/styles"
import { mainTheme } from "./constants/styles"
import Navigation from './components/main/Navigation'
import ForgotPassword from './components/main/ForgotPassword'

ReactDOM.render(
  <MuiThemeProvider theme={mainTheme}>
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={() => <Navigation />} />
        <Route exact path="/forgot_password" component={() => <ForgotPassword />} />
      </Switch>
    </BrowserRouter>
  </MuiThemeProvider>,
  document.getElementById("root")
)
