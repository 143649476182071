import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { palette, mainTheme } from '../../constants/styles'
import Button from '@material-ui/core/Button'
import logoIcon from '../../images/logoWhite.png'
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

import Staff from './Staff'
import Tables from './Tables'
import Users from './Users'
import Reservations from './Reservations'
import StaffReport from './StaffReport'
import CustomerReport from './CustomerReport'
import Birthdays from './Birthdays'

const drawerWidth = 300

const styles = {
  root: {
    display: 'flex',
  },
  appBar: {
    background: palette.black[0],
    zIndex: mainTheme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  content: {
    height: '100vh',
    width: '100%',
    overflowY: 'auto',
    padding: mainTheme.spacing(3),
  },
  logoIcon: {
    objectFit: "contain",
    height: "40px",
    width: "40px",
  },
  logoTitle: {
    marginLeft: '10px',
    color: palette.white[0],
    height: '100%',
    lineHeight: '100%',
    paddingTop: '12.5px',
    flexGrow: 1

  },
  listItemText: {
    marginLeft: '10px'
  }
}

class Main extends Component {
  state = {
    selectedIndex: 0
  }

  handleListItemClick = (event, index) => {
    this.setState({
      selectedIndex: index,
    })
  }

  handleClose = () => {
    const { updatedLogin } = this.props

    updatedLogin(false)
  }

  currentTable = (index) => {
    if (index === 0) {
      return (<Staff />)
    } else if (index === 1) {
      return (<Tables />)
    } else if (index === 2) {
      return (<Users />)
    } else if (index === 3) {
      return (<Reservations />)
    } else if (index === 4) {
      return (<StaffReport />)
    } else if (index === 5) {
      return (<CustomerReport />)
    } else if (index === 6) {
      return (<Birthdays />)
    } else {
      return (<div />)
    }
  }

  render() {
    const { classes } = this.props

    const { selectedIndex } = this.state

    return (

      <div className={classes.root}>
        <CssBaseline />
        <AppBar position='fixed' className={classes.appBar}>
          <Toolbar>
            <img src={logoIcon} className={classes.logoIcon} alt="" />
            <Typography className={classes.logoTitle} variant="h4" gutterBottom>
              Toronto
            </Typography>
            <Button variant="contained" autoFocus onClick={this.handleClose}>
              Sign out
            </Button>
          </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant='permanent'
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <Toolbar />
          <div className={classes.drawerContainer}>
            <List>
              {['Staff', 'Tables', 'Clients', 'Reservations', "Staff Report - Rating & Revenue", "Client Report - Revenue", "Upcoming Birthdays"].map((text, index) => (
                <ListItem button key={text} selected={selectedIndex === index} onClick={(event) => this.handleListItemClick(event, index)}>
                  <ListItemText className={classes.listItemText} primary={text} />
                </ListItem>
              ))}
            </List>
          </div>
        </Drawer>
        <main className={classes.content}>
          <Toolbar />
          {
            this.currentTable(selectedIndex)
          }
        </main>
      </div>
    )
  }
}

Main.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Main)