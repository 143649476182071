import React, { Component } from "react"
import PropTypes from "prop-types"
import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import { withStyles } from "@material-ui/core/styles"
import { palette } from "../../constants/styles"
import logoIcon from "../../images/logo.png"
import Footer from "./Footer"
import CustomSnackbar from "../shared/CustomSnackbar"

const axios = require("axios")
var store = require("store")

const styles = {
  button: {
    margin: "30px 0 0 0",
  },

  container: {
    display: "flex",
    justifyContent: "center",
  },

  contentContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: "40px 20px 40px 20px",

    "@media (min-width:780px)": {
      padding: "40px 40px 40px 40px",
    },
  },

  footer: {
    margin: "100vh 0 0 0",
    width: "100%",
  },

  paper: {
    position: "absolute",
    width: "100%",
    boxShadow: "none",
    borderRadius: "5px",

    "@media (min-width:780px)": {
      top: "20%",
      width: "500px",
      border: "1px solid",
      borderColor: palette.gray[0],
    },
  },

  linkContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: "25px 0 30px 0",
    width: "100%",
  },

  link: {
    color: palette.gray[2],
    fontWeight: 700,
    fontSize: "15px",
    textDecoration: "none",

    "&:hover": {
      textDecoration: "underline",
    },
  },

  logoIcon: {
    marginBottom: "30px",
    objectFit: "contain",
    height: "60px",
    width: "60px",
  },

  textField: {
    marginTop: "25px",
  },
}

class Signin extends Component {
  state = {
    email: "",
    emailHelperText: "",
    emailError: false,
    password: "",
    passwordHelperText: "",
    passwordError: false,
    snackbarIsOpen: false,
    snackbarVariant: "success",
    snackbarMessage: "",
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
      emailHelperText: "",
      emailError: false,
      passwordHelperText: "",
      passwordError: false,
    })
  }

  handleOnClick = (event) => {
    this.validateForms()
  }

  onKeyPress = (e) => {
    if (e.key === "Enter") {
      this.validateForms()
    }
  }

  validateForms() {
    const { email, password } = this.state

    var emailHasError = false
    var emailErrorText = ""

    if (email === "") {
      emailHasError = true
      emailErrorText = "Please enter a valid email."
    }

    var passwordHasError = false
    var passwordErrorText = ""

    if (password === "") {
      passwordHasError = true
      passwordErrorText = "Please enter a password."
    }

    if (emailHasError || passwordHasError) {
      this.displayFormError(
        emailHasError,
        emailErrorText,
        passwordHasError,
        passwordErrorText
      )
    } else {
      this.signUserIn(email, password)
    }
  }

  displayFormError(
    emailHasError,
    emailErrorText,
    passwordHasError,
    passwordErrorText
  ) {
    this.setState({
      emailError: emailHasError,
      emailHelperText: emailErrorText,
      passwordError: passwordHasError,
      passwordHelperText: passwordErrorText,
    })
  }

  onSnackBarClose = () => {
    this.setState({
      snackbarIsOpen: false,
    })
  }

  displaySnackbar = (variant, message) => {
    this.setState({
      snackbarIsOpen: true,
      snackbarVariant: variant,
      snackbarMessage: message,
    })
  }

  signUserIn(email, password) {
    const { updatedLogin } = this.props

    this.setState({ isLoading: true })

    axios.post('https://suite-44-api.herokuapp.com/admin/login', {
      email: email,
      password: password
    })
      .then((response) => {
        console.log(response.data)
        store.set('token', response.data.token)
        setTimeout(function () {
          this.setState({
            isLoading: false
          })
          updatedLogin(true)
        }.bind(this), 1000)
      }, (error) => {
        this.setState({
          isLoading: false
        })
        this.displayFormError(true, "", true, "Your email and password did not match. Please try again.")
      })
  }

  render() {
    const { classes } = this.props
    const {
      email,
      emailHelperText,
      emailError,
      password,
      passwordHelperText,
      passwordError,
      isLoading,
      snackbarIsOpen,
      snackbarMessage,
      snackbarVariant,
    } = this.state

    return (
      <div className={classes.container}>
        <Paper className={classes.paper}>
          <div className={classes.contentContainer}>
            <img src={logoIcon} className={classes.logoIcon} alt="" />

            <Typography variant="h4" gutterBottom>
              Sign in to 44 Toronto
            </Typography>

            <TextField
              onKeyPress={this.onKeyPress}
              fullWidth
              error={emailError}
              className={classes.textField}
              id="outlined-email"
              label="Email"
              name="email"
              type="email"
              helperText={emailHelperText}
              value={email}
              onChange={this.handleChange("email")}
              variant="outlined"
            />

            <TextField
              onKeyPress={this.onKeyPress}
              fullWidth
              error={passwordError}
              className={classes.textField}
              id="outlined-password"
              label="Password"
              name="password"
              type="password"
              value={password}
              helperText={passwordHelperText}
              onChange={this.handleChange("password")}
              variant="outlined"
            />

            <Button
              className={classes.button}
              size="large"
              variant={"contained"}
              color="primary"
              fullWidth
              onClick={this.handleOnClick}
            >
              {isLoading ? (
                <CircularProgress
                  variant="indeterminate"
                  disableShrink
                  size={24}
                  thickness={4}
                />
              ) : (
                "Sign in"
              )}
            </Button>
          </div>
        </Paper>
        <div className={classes.footer}>
          <Footer />
        </div>

        <CustomSnackbar
          variant={snackbarVariant}
          message={snackbarMessage}
          open={snackbarIsOpen}
          onSnackBarClose={this.onSnackBarClose}
        />
      </div>
    )
  }
}

Signin.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Signin)


/*<div className={classes.linkContainer}>
              <div />
              <a className={classes.link} href={"/forgot_password"}>
                Forgot Password?
              </a>
            </div>*/