import React, { useState, useEffect } from 'react'
import MaterialTable from 'material-table'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import CheckIcon from '@material-ui/icons/Check'
import SearchIcon from '@material-ui/icons/Search'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import LastPageIcon from '@material-ui/icons/LastPage'
import PreviousPageIcon from '@material-ui/icons/SkipPrevious'
import NextPageIcon from '@material-ui/icons/SkipNext'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ClearIcon from '@material-ui/icons/Clear'

const moment = require("moment")
const axios = require("axios")
var store = require("store")

export default function Birthdays() {
  const [data, setData] = useState([])

  useEffect(() => {
    getUsers()
  }, [])

  const getUsers = () => {
    let currentMonth = parseInt(moment(new Date()).format("M"))
    var nextMonth = currentMonth + 1

    if (currentMonth == 12) {
      nextMonth = 1
    }

    let currentDate = parseInt(moment(new Date()).format("D"))
    console.log("Current Date", currentDate)
    console.log("Current Month", currentMonth)

    axios.get('https://suite-44-api.herokuapp.com/users/birthday/' + currentMonth + "/" + nextMonth, {
      headers: {
        'x-access-token': store.get('token')
      }
    })
      .then((response) => {
        var userList = []
        for (let x in response.data) {
          let user = response.data[x]

          if (user.birthdayMonth === currentMonth) {
            if (user.birthdayDay > currentDate) {
              userList.push(user)
            }
          } else {
            userList.push(user)
          }
        }

        console.log(userList)
        setData(userList)
      })
  }

  return (
    <div>
      <div style={{ paddingTop: '12px' }}>
        <MaterialTable
          columns={[
            { title: 'First Name', field: 'firstName' },
            { title: 'Last Name', field: 'lastName' },
            { title: 'Email', field: 'email' },
            { title: 'Phone Number', field: 'phone' },
            { title: 'Birthday', field: 'birthday', type: 'date' }
          ]}
          data={data}
          title={<h1>Upcoming Client Birthdays</h1>}
          options={{
            pageSize: 10,
            pageSizeOptions: [10, 50, 100]
          }}
          icons={{
            Add: props => <AddIcon {...props} />,
            Edit: props => <EditIcon {...props} />,
            Delete: props => <DeleteIcon {...props} />,
            Clear: props => <DeleteIcon {...props} />,
            Check: props => <CheckIcon {...props} />,
            Search: props => <SearchIcon {...props} />,
            ResetSearch: props => <ClearIcon {...props} />,
            FirstPage: props => <FirstPageIcon {...props} />,
            LastPage: props => <LastPageIcon {...props} />,
            PreviousPage: props => <PreviousPageIcon {...props} />,
            NextPage: props => <NextPageIcon {...props} />,
            Clear: props => <ClearIcon {...props} />,
            SortArrow: props => <ArrowDownwardIcon {...props} />
          }}
          editable={false}
        />
      </div>
    </div>
  )
}