import React, { useState, useEffect } from 'react'
import MaterialTable from 'material-table'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import CheckIcon from '@material-ui/icons/Check'
import SearchIcon from '@material-ui/icons/Search'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import LastPageIcon from '@material-ui/icons/LastPage'
import PreviousPageIcon from '@material-ui/icons/SkipPrevious'
import NextPageIcon from '@material-ui/icons/SkipNext'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ClearIcon from '@material-ui/icons/Clear'

const moment = require("moment")
const axios = require("axios")
var store = require("store")

export default function Users() {
  const [data, setData] = useState([])

  useEffect(() => {
    getUsers()
  }, [])

  const getUsers = () => {
    axios.get('https://suite-44-api.herokuapp.com/users', {
      headers: {
        'x-access-token': store.get('token')
      }
    })
      .then((response) => {
        setData(response.data)
      })
  }

  return (
    <div>
      <div style={{ paddingTop: '12px' }}>
        <MaterialTable
          columns={[
            { title: 'First Name', field: 'firstName' },
            { title: 'Last Name', field: 'lastName' },
            { title: 'Email', field: 'email' },
            { title: 'Phone Number', field: 'phone' },
            { title: 'Date of Birth', field: 'birthday', type: 'date' }
          ]}
          data={data}
          title={<h1>Clients</h1>}
          options={{
            pageSize: 10,
            pageSizeOptions: [10, 50, 100]
          }}
          icons={{
            Add: props => <AddIcon {...props} />,
            Edit: props => <EditIcon {...props} />,
            Delete: props => <DeleteIcon {...props} />,
            Clear: props => <DeleteIcon {...props} />,
            Check: props => <CheckIcon {...props} />,
            Search: props => <SearchIcon {...props} />,
            ResetSearch: props => <ClearIcon {...props} />,
            FirstPage: props => <FirstPageIcon {...props} />,
            LastPage: props => <LastPageIcon {...props} />,
            PreviousPage: props => <PreviousPageIcon {...props} />,
            NextPage: props => <NextPageIcon {...props} />,
            Clear: props => <ClearIcon {...props} />,
            SortArrow: props => <ArrowDownwardIcon {...props} />
          }}
          editable={{
            onRowAdd: newData =>

              axios.post('https://suite-44-api.herokuapp.com/users', {
                firstName: newData.firstName,
                lastName: newData.lastName,
                email: newData.email,
                phone: newData.phone,
                birthday: newData.birthday,
                birthdayMonth: moment(newData.birthday).format("M"),
                birthdayDay: moment(newData.birthday).format("D")
              }, {
                headers: {
                  'x-access-token': store.get('token')
                }
              })
                .then((response) => {
                  setData([...data, response.data])
                }, (error) => {
                  console.log(error)
                }),
            onRowUpdate: (newData, oldData) =>
              axios.patch('https://suite-44-api.herokuapp.com/users/' + oldData._id, {
                firstName: newData.firstName,
                lastName: newData.lastName,
                email: newData.email,
                phone: newData.phone,
                birthday: newData.birthday,
                birthdayMonth: moment(newData.birthday).format("M"),
                birthdayDay: moment(newData.birthday).format("D")
              }, {
                headers: {
                  'x-access-token': store.get('token')
                }
              })
                .then((response) => {
                  const dataUpdate = [...data]
                  const index = oldData.tableData.id
                  dataUpdate[index] = newData
                  setData([...dataUpdate])
                }, (error) => {
                  console.log(error)
                }),
            onRowDelete: oldData =>
              axios.delete('https://suite-44-api.herokuapp.com/users/' + oldData._id, {
                headers: {
                  'x-access-token': store.get('token')
                }
              })
                .then((response) => {
                  const dataDelete = [...data]
                  const index = oldData.tableData.id
                  dataDelete.splice(index, 1)
                  setData([...dataDelete])
                }, (error) => {
                  console.log(error)
                })
          }}
        />
      </div>
    </div>
  )
}